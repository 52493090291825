.notify-modal,
.sticky-modal {
	.form-label {
		@include font-size($font-size-sm);
	}
}

//
// Notify
//

.notify-control {
	display: flex;
	align-items: center;
	margin-bottom: $paragraph-margin-bottom;
	
	.btn {
		margin-left: .25rem;
	}
}

.notify-item {
	margin-bottom: $grid-gutter-width / 2;
	@include transition($transition-fade);
	
	&:hover,
	&:focus {
		opacity: .8;
	}
	
	&-header,
	&-footer {
		display: flex;
		align-items: center;
		@include font-size($font-size-sm);
	}
	
	&-header {
		margin-bottom: .25rem;
		justify-content: space-between;
	}
	
	&-body {
		display: block;
		padding: .25rem .5rem;
		color: inherit;
		text-decoration: none;
		background-color: $white;
		@include transition($transition-fade);
		@include border-radius($border-radius-sm);
		
		&:hover,
		&:focus {
			color: inherit;
		}
	}
}

//
// Sticky
//

.sticky-container {
	column-count: 2;
	column-gap: $grid-gutter-width / 2;
	
	@include media-breakpoint-up(sm) {
		column-count: 4;
	}
	
	@include media-breakpoint-up(md) {
		column-count: 6;
	}
	
	@include media-breakpoint-up(xl) {
		column-count: 8;
	}
}

.sticky-item {
	display: block;
	margin-bottom: $grid-gutter-width / 2;
	color: inherit;
	background-color: $white;
	text-decoration: none;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
	@include border-radius($border-radius-sm);
	@include transition($transition-fade);
	
	&:hover,
	&:focus {
		color: inherit;
		opacity: .8;
	}
	
	&-body,
	&-footer {
		padding: .25rem .5rem;
		white-space: initial;
	}
	
	&-footer {
		display: flex;
		justify-content: flex-end;
		color: $text-muted;
		@include font-size($font-size-sm);
	}
}

.sticky-control {
	display: flex;
	align-items: center;
	margin-bottom: $paragraph-margin-bottom / 2;
	
	&-title {
		margin-bottom: 0;
		@include font-size($font-size-lg);
	}
}

//
// Theme
//

@each $color, $value in $colors {
	.notify-item-#{$color} .notify-item-body,
	.sticky-item-#{$color} {
		background-color: rgba($value, .125);
	}
}