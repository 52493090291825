.report-item,
.report-control {
	p {
		margin-bottom: $paragraph-margin-bottom / 2;
	}
}

.report-item {
	.dropdown-menu {
		padding-top: 0;
		padding-bottom: 0;
	}
	
	.editable-input {
		min-width: 15rem;
	}
}

// Toast
.toast-container.report {
	width: 100%;
	max-width: 45rem;
	z-index: $zindex-offcanvas;
	
	.toast {
		width: 100%;
		max-width: inherit !important;
		border-bottom: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	
	.toast-body {
		max-height: 80vh;
		overflow-y: auto;
	}
}