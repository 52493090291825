/*!
 * Bootstrap v5.0.0-beta3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import "~bootstrap/scss/vendor/rfs";

// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
@import "./variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/modal";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

@import "./bootstrap";
@import "./forms";
@import "./task";
@import "./report";