@import "~emoji-mart/css/emoji-mart.css";

.form-group {
	margin-bottom: $spacer;
	
	&.row {
		.form-label {
			margin-top: $form-label-margin-bottom;
		}
	}
}

.form-inline {
	display: flex;
	flex-direction: column;
	
	.form-control {
		margin-bottom: $form-label-margin-bottom;
	}
	
	@include media-breakpoint-up(md) {
		flex-direction: row;
		align-items: center;
		
		.form-control,
		.form-select {
			margin-bottom: 0;
			max-width: 8rem;
			
			+ .form-control,
			+ .form-select,
			+ .btn {
				margin-left: $form-label-margin-bottom;
			}
		}
	}
}

//
// Editable
//

[contenteditable=true] {
	&:empty:before {
		display: block;
		content: attr(placeholder);
		color: $text-muted;
		pointer-events: none;
	}
	
	&:focus,
	&:focus-visible {
		outline: none;
	}
}

.editable {
	border: solid $input-border-width $input-border-color;
	@include border-radius($input-border-radius);
	
	.dropdown-menu & {
		border: 0;
	}
}

.editable-input {
	padding: $input-padding-y $input-padding-x;
	min-height: $font-size-base * 4;
}

.editable-action {
	display: flex;
	align-items: center;
	padding: $input-padding-y-sm $input-padding-x-sm;
	
	.dropdown-menu {
		padding-top: 0;
		padding-bottom: 0;
	}
	
	.emoji-mart {
		border: 0;
	}
}