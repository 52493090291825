* {
	word-break: break-all;
}

button,
.btn {
	cursor: pointer;
	
	&:focus {
		outline: none !important;
	}
}

mark,
.mark {
	background: linear-gradient(transparent 60%, $mark-bg 60%);
}

.bg-transparent {
	background-color: rgba($white, .25) !important;
}

//
// Alert
//

.alert-with-icon {
	display: flex;
	align-items: center;
	
	[class^="fa"] {
		margin-right: .5rem;
		width: $font-size-lg;
		text-align: center;
		@include font-size($font-size-lg);
	}
}

@each $color, $value in $theme-colors {
	.alert-#{$color} {
		color: $white;
		
		.alert-link {
			color: inherit;
		}
	}
	
	.alert-outline-#{$color} {
		color: $value;
		border-color: $value;
		
		.alert-link {
			color: inherit;
		}
	}
}

.alert-light {
	color: $text-muted;
}

//
// Badge
//

.badge {
	[class^="fa"] {
		margin-right: .25rem;
	}
}

//
// Badge
//

@each $color, $value in $theme-colors {
	.badge-#{$color} {
		background-color: $value;
	}
	
	.badge-outline-#{$color} {
		color: $value;
		border: solid $border-width $value;
	}
}

.badge-light {
	color: $text-muted;
}

//
// Buttons
//

@each $color, $value in $theme-colors {
	.btn-#{$color} {
		@include box-shadow(0 2px 4px rgba($value, .25));
	}
}

.btn-light {
	color: $text-muted;
	
	.input-group & {
		border: solid $border-width $border-color;
	}
}

.btn-transparent {
	&:hover,
	&:focus {
		box-shadow: none;
		@include gradient-bg($gray-300);
	}
}

.btn-mask {
	width: 2.5rem;
	text-align: center;
	
	&,
	&:focus {
		border: 0;
		outline: 0;
	}
	
	.input-group-text & {
		margin-left: -$input-group-addon-padding-x;
		margin-right: -$input-group-addon-padding-x;
	}
}

//
// Card
//

.card {
	@include box-shadow($box-shadow);
}

@include media-breakpoint-up(md) {
	.card-group {
		@include box-shadow($box-shadow);
		
		.card {
			box-shadow: none;
		}
	}
}

.card-title {
	@include font-size($font-size-lg);
}

.card-header {
	.card-title,
	p {
		margin-bottom: 0;
	}
}

@each $color, $value in $theme-colors {
	.card-#{$color} {
		border-top: solid 2px $value;
	}
}

//
// Collapse
//

.collapse-toggler {
	&::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		margin-left: .25rem;
		@include caret-up;
	}
	
	&.collapsed {
		&::after {
			@include caret-down;
		}
	}
}

//
// Dropdown
//

.dropdown-menu {
	[class^="fa"] {
		margin-right: .5rem;
		width: $font-size-lg;
		text-align: center;
	}
}

.dropdown-header {
	text-align: center;
}

//
// Jumbotron
//

.jumbotron {
	margin-bottom: $paragraph-margin-bottom;
	padding: $spacer * 2;
	background-color: $gray-200;
	@include border-radius($border-radius);
	
	:last-child {
		margin-bottom: 0;
	}
}

.jumbotron-header {
	display: flex;
	align-items: center;
	margin-bottom: $paragraph-margin-bottom;
	
	* {
		margin-bottom: 0;
	}
}

//
// Modal
//

.modal-header {
	&.sticky-top {
		background-color: $modal-content-bg;
	}
}

.modal-title {
	@include font-size($font-size-lg);
}

.modal-subtitle {
	margin-bottom: $headings-margin-bottom;
	@include font-size($font-size-base);
}

.modal-frame {
	border: 0;
	outline: 0;
	min-height: 30rem;
	@include border-radius($modal-content-inner-border-radius);
}

.modal-container {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.modal-close {
	@include visually-hidden();
}

//
// Navs
//

.nav-fill {
	.nav-link {
		border-bottom: solid 2px $gray-300;
		
		&.active {
			color: $primary;
			border-bottom-color: $primary;
		}
	}
}

.nav-tabs {
	.nav-link.active,
	.nav-item.show .nav-link {
		font-weight: $font-weight-bold;
	}
}

//
// Pagination
//

.pagination {
	a {
		position: relative;
		display: block;
		padding: $pagination-padding-y $pagination-padding-x;
		color: $pagination-color;
		text-decoration: if($link-decoration == none, null, none);
		background-color: $pagination-bg;
		border: $pagination-border-width solid $pagination-border-color;
		@include transition($pagination-transition);
		
		&:hover {
			z-index: 2;
			color: $pagination-hover-color;
			text-decoration: if($link-hover-decoration == underline, none, null);
			background-color: $pagination-hover-bg;
			border-color: $pagination-hover-border-color;
		}

		&:focus {
			z-index: 3;
			color: $pagination-focus-color;
			background-color: $pagination-focus-bg;
			outline: $pagination-focus-outline;
			box-shadow: $pagination-focus-box-shadow;
		}
	}
	
	li {
		&:not(:first-child) a {
			margin-left: $pagination-margin-start;
		}

		&.active a {
			z-index: 3;
			color: $pagination-active-color;
			@include gradient-bg($pagination-active-bg);
			border-color: $pagination-active-border-color;
		}

		&.disabled a {
			color: $pagination-disabled-color;
			pointer-events: none;
			background-color: $pagination-disabled-bg;
			border-color: $pagination-disabled-border-color;
		}
		
		@if $pagination-margin-start == (-$pagination-border-width) {
			&:first-child {
				a {
					@include border-start-radius($pagination-border-radius);
				}
			}
			
			&:last-child {
				a {
					@include border-end-radius($pagination-border-radius);
				}
			}
		} @else {
			a {
				@include border-radius($pagination-border-radius);
			}
		}
	}
}

//
// Toast
//

.toast-container {
	position: fixed;
	top: auto;
	bottom: 0;
	right: 0;
	padding-left: $spacer;
	padding-right: $spacer;
	z-index: $zindex-modal-backdrop;
}

.toast-header {
	.collapse-toggler {
		display: block;
		width: 100%;
		color: inherit;
		text-decoration: none;
		
		&::after {
			@include caret-down;
		}

		&.collapsed {
			&::after {
				@include caret-up;
			}
		}
	}
}

//
// Form
//

.form-control-plaintext {
	&:focus,
	&:focus-visible {
		outline: 0;
	}
}

//
// Table
//

.table {
	> thead {
		vertical-align: $table-cell-vertical-align;
	}
}

.table-light {
	th, td {
		color: $text-muted;
		background-color: $gray-200;
	}
}

.table-responsive {
	margin-bottom: $spacer;
	
	.table {
		margin-bottom: 0;
	}
}

.regular_holiday,
.holiday {
	th,
	td {
		background-color: $gray-200;
	}
}